import UserHeader from "../../components/UserHeader/UserHeader";
import { getToken, logout } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deactivateProfile,
  getUserProfile,
  updateProfile,
} from "../../modules/auth/actions";
import moment from "moment";
import Web3 from "web3";
import S3FileUpload, { deleteFile } from "react-s3";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Button, Modal } from "react-bootstrap";
import Spinner from "../../components/Loader";

function MyProfilePage() {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const [web3Enabled, setWeb3Enabled] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [balance, setBalance] = useState([]);

  const [mode, editMode] = useState(false);

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const [gender, setGender] = useState("female");

  const [userDob, setUserDob] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [city, setCity] = useState("");

  const [country, setCountry] = useState("");

  const [data, setData] = useState("");

  const [properties, setProperties] = useState({});

  const [coinData, setCoinData] = useState({});

  const [shareHolding, setShareHolding] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    data === "" ? setData("") : setData(data);
  }, [data]);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_BUCKET_DIR,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const logoutUser = () => {
    logout();
    window.location.reload(true);
    navigate("/login");
  };

  useEffect(() => {
    getUserData();
    ethEnabled();
  }, []);

  const metamaskDetails = async () => {
    setWeb3Enabled(true);
    var accs = await web3.eth.getAccounts();
    const newAccounts = await Promise.all(
      accs.map(async (address) => {
        const balance = await web3.eth.getBalance(address);
        let value = Web3.utils.fromWei(balance, "ether");
        setBalance(Math.floor(value * 1000) / 1000);
      })
    );

    setAccounts(accs);
  };

  const getUserData = () => {
    setisLoading(true);
    getUserProfile().then(
      (res) => {
        var user = res.data[0];
        setCoinData(res.coinBalance.data);
        setProperties(res.listProperties.data[0].property_count);
        setShareHolding(res.shareHolding.data[0]);
        setUserData(user);
        setImageUrl(user.avatar);
        setCity(user.city);
        setCountry(user.country);
        setGender(user.gender);
        setUserDob(user.dob);
        setPhoneNumber(user.mobile);
        setisLoading(false);
        setData(`${user.city}, ${user.country}`);
      },
      (err) => {
        setUserData(null);
        // console.log(err);
        setisLoading(false);
      }
    );
  };

  let web3 = new Web3();

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const handleImage = async (e) => {
    setIsUploadingImage(true);
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/jpg" ||
      file.type === "image/webp"
    ) {
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          setImageUrl(data.location);
          // let item = { type: 0, path: data.location };
          // setAllImages([...allImages, item]);
          setIsUploadingImage(false);
        })
        .catch((err) => {
          alert(err);
          setIsUploadingImage(false);
        });
    } else {
      showErrorMsg("File Type not supported");
      setIsUploadingImage(false);
    }
  };

  const setProfile = () => {
    editMode(false);
    var params = {
      avatar: imageUrl,
      city: city,
      country: country,
      dob: userDob,
      gender: gender,
      mobile: phoneNumber,
    };
    updateProfile(params).then(
      (res) => {
        showSuccessMsg("Updated successfully");
        getUserData();
      },
      (err) => {
        showErrorMsg(err.message);
      }
    );
  };

  const handleDate = (e) => {
    let date = e.target.value;
    setUserDob(date);
  };

  const setLocation = (data) => {
    let location = data?.value?.terms;
    // let geoVal;
    if (location.length > 3) {
      setCountry(location[3].value);
      setCity(location[1].value);
    } else {
      setCountry(location[2].value);
      setCity(location[0].value);
    }
  };

  const closeAccount = () => {
    deactivateProfile().then(
      (res) => {
        if (res.statuscode === 200) {
          let mailData = res?.data?.mail;
          setShow(false);
          if (mailData === true) {
            showSuccessMsg(
              "Your account will be deactivated soon between 24 - 48 hours. Your Request has been received to support team"
            );
          } else {
            showSuccessMsg("Your account has been deactivated.");
          }
          setTimeout(() => {
            logoutUser();
          }, 3000);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  return (
    <>
      {show && (
        <Modal show={show} onClick={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Account Deactivation Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="">Do you really want to deactivate this account?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={closeAccount}>
              Yes, Deactivate it
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <UserHeader />
      {isLoading && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      <section className="profle_content">
        <div className="container">
          {!isLoading && (
            <>
              {!mode ? (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                    <h3>
                      My profile <span onClick={logoutUser}>Logout</span>
                    </h3>

                    <div className="profile_details">
                      <div className="profle_img">
                        {imageUrl !== null && imageUrl !== "" ? (
                          <img src={imageUrl} alt="" />
                        ) : (
                          <img
                            src={`https://blits-estates-assets.s3.amazonaws.com/imageAssets/60111.jpg`}
                            alt=""
                          />
                        )}
                      </div>
                      <h1>{userData?.user_name}</h1>
                      {userData?.city !== null && userData?.city !== "" ? (
                        <small>
                          {userData?.city + ", " + userData?.country}
                        </small>
                      ) : (
                        <small>No Location Added</small>
                      )}

                      <div className="born_date">
                        {userData?.dob !== null && userData?.dob !== ""
                          ? `${moment(userData?.dob).format("YYYY")} Born`
                          : "No Dob Added"}
                        <br />
                        {userData?.gender !== null && userData?.gender !== ""
                          ? userData?.gender
                          : "No gender Added"}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side pt-5">
                    <div className="proprty_title">
                      <div className="proprty_title_in">
                        <h1>Contact details</h1>
                      </div>
                      <button
                        type="button"
                        className="def-btn"
                        onClick={(e) => editMode(true)}
                      >
                        Edit profile
                      </button>
                    </div>

                    <div className="profile_description">
                      <small>Email address</small>
                      <strong>{userData?.email}</strong>
                      {userData?.mobile !== null && userData?.mobile !== "" ? (
                        <>
                          <small>Mobile number</small>
                          <strong>+{userData?.mobile}</strong>
                        </>
                      ) : null}
                    </div>

                    <h2>Account details</h2>

                    <div className="profile_description">
                      <small>Account created on</small>
                      <strong>
                        {moment(userData?.created_at).format("DD MMM YYYY")} -{" "}
                        {moment(userData?.created_at).fromNow()}
                      </strong>

                      <small>Total listing</small>

                      <strong>
                        {properties === 0
                          ? "No property"
                          : properties === 1
                          ? "1 property"
                          : properties > 1
                          ? properties + " properties"
                          : null}
                      </strong>

                      <small>Share holding</small>
                      <strong>
                        {shareHolding.transaction_count} properties
                      </strong>

                      {accounts.length > 0 ? (
                        <>
                          <small>Current token balance</small>
                          <strong>
                            {coinData.coinBalance > 0
                              ? coinData.coinBalance
                              : 0}{" "}
                            Coins
                          </strong>

                          <small>Connected wallet</small>
                          <strong>Metamask - {accounts}</strong>
                        </>
                      ) : (
                        <div
                          type="button"
                          className="def-btn"
                          onClick={ethEnabled}
                        >
                          Connect Metamask
                        </div>
                      )}
                    </div>

                    <div className="view_listing col-12">
                      <a href="#">
                        Keep changing your password for added security
                      </a>
                    </div>
                    <div className="view_listing col-12">
                      <button
                        className="more-det"
                        onClick={(e) => setShow(true)}
                      >
                        Deactivate account
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                    <h3>Edit profile</h3>

                    <div className="profile_details">
                      <div className="profle_img">
                        <label className="replacer">
                          <img src={imageUrl} alt="" className="image" />
                          <div className="middle">
                            <input
                              type="file"
                              className="imageUploadData"
                              name="user_image"
                              onChange={(event) => handleImage(event)}
                            />
                            <div className="heading-overall hover-heading">
                              Replace Image
                            </div>
                          </div>
                        </label>
                        {isUploadingImage ? "Loading. Please wait..." : ""}
                      </div>
                      <h1>{userData?.user_name}</h1>
                      {city !== null && city !== "" ? (
                        <small>{city + ", " + country}</small>
                      ) : (
                        <small>No Location Added</small>
                      )}
                      <GooglePlacesAutocomplete
                        debounce={800}
                        apiKey={process.env.REACT_APP_MAPS_KEY}
                        selectProps={{
                          defaultInputValue: data, //set default value
                          onChange: setLocation, //save the value gotten from google
                          placeholder: "Enter Location",
                          styles: {
                            container: (provided) => ({
                              ...provided,
                              display: "inline-block",
                              textAlign: "-webkit-center",
                              // width: "100%",
                              minHeight: "1px",
                              border: "none",
                              fontSize: "16px",
                              width: "250px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              border: "2px solid #cccccc",
                              borderRadius: "6px",
                              width: "90%",
                            }),
                            input: (provided) => ({
                              ...provided,
                              minHeight: "1px",
                              height: "30px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              minHeight: "1px",
                              paddingTop: "0",
                              paddingBottom: "0",
                              color: "#c0c0c0",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              minHeight: "1px",
                              height: "24px",
                              background: "#fff",
                            }),
                            clearIndicator: (provided) => ({
                              ...provided,
                              minHeight: "1px",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              //   minHeight: '1px',
                              //   height: '40px',
                              paddingTop: "0",
                              paddingBottom: "0",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              minHeight: "1px",
                              height: "20px",
                              paddingBottom: "2px",
                            }),
                          },
                        }}
                        onLoadFailed={(error) => {
                          // console.log(error);
                        }}
                      />
                      <br />
                      <br />
                      <input
                        placeholder="Enter Date"
                        className="dob_field"
                        value={userDob}
                        onChange={(e) => handleDate(e)}
                        type={`date`}
                        min="1950-01-01"
                        max="2030-01-01"
                      />
                      <br />
                      <br />
                      <select
                        className="gender_select"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option disabled>Select a gender</option>
                        <option>Female</option>
                        <option>Male</option>
                      </select>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side pt-5">
                    <div className="proprty_title">
                      <div className="proprty_title_in">
                        <h1>Enter Contact details</h1>
                      </div>
                      <button
                        type="button"
                        className="def-btn"
                        onClick={(e) => setProfile()}
                      >
                        Save profile
                      </button>
                    </div>

                    <div className="profile_description">
                      <PhoneInput
                        country={"in"}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                      />
                    </div>
                    {/* <div className="profile_description">
                  <small>Email address</small>
                  <strong>{userData?.email}</strong>

                  <small>Mobile number</small>
                  <strong>+91 9633 262 579</strong>
                </div>

                <h2>Account details</h2>

                <div className="profile_description">
                  <small>Account created on</small>
                  <strong>
                    {moment(userData?.created_at).format("DD MMM YYYY")} -{" "}
                    {moment(userData?.created_at).fromNow()}
                  </strong>

                  <small>Total listing</small>
                  <strong>2 propertires</strong>

                  <small>Share holding</small>
                  <strong>58 shares/6 properties</strong>
                  {accounts.length > 0 ? (
                    <>
                      <small>Current token balance</small>
                      <strong>{balance} Coins</strong>

                      <small>Connected wallet</small>
                      <strong>Metamask - {accounts}</strong>
                    </>
                  ) : (
                    <div type="button" className="def-btn" onClick={ethEnabled}>
                      Connect Metamask
                    </div>
                  )}
                </div>

                <div className="view_listing col-12">
                  <a href="#">Keep changing your password for added security</a>
                </div>
                <div className="view_listing col-12">
                  <button className="more-det">Change password</button>
                </div> */}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default MyProfilePage;
