import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";

import { getUserProfile } from "../../modules/auth/actions";
import { useEffect, useState } from "react";
import { logout } from "../../utils/helper";
import { showWarningMsg } from "../../utils/notification";
import logoImg from "../../assets/images/logo.svg";

const Header = () => {
  const [userName, setUsername] = useState(null);

  const [userImg, setUserImg] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setisLoading(true);
    getUserProfile().then(
      (res) => {
        // console.log('res',res.data[0].user_name)
        var user =
          res.data[0].user_name !== null ? res.data[0].user_name : "NA";
        setUsername(user);
        setUserImg(res.data[0].avatar);
        setisLoading(false);
      },
      (err) => {
        setUsername(null);
        console.log(err);
        setisLoading(false);
      }
    );
  };

  const logoutUser = () => {
    logout();
    setUsername(null);
    navigate("/login");
  };

  const checkAuthandRoute = () => {
    if (userName !== null) {
      navigate("/add-property");
    } else {
      showWarningMsg("Please Login/Signup to List your Property");
      setTimeout(() => {
        navigate("/signup");
      }, 2000);
    }
  };

  const checkAuthandRouteCoin = () => {
    if (userName !== null) {
      navigate("/buytoken");
    } else {
      showWarningMsg("You need to create an account before buying tokens.");
      setTimeout(() => {
        navigate("/signup");
      }, 2000);
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-lg-9 col-sm-9 col-12 nav-wrap">
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <img src={logoImg} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/property">Marketplace</Nav.Link>
                  <Nav.Link href="/blog">Blog</Nav.Link>
                  <NavDropdown
                    title="About"
                    id="basic-nav-dropdown"
                    className="blits_nav"
                  >
                    <NavDropdown.Item
                      // href="/"
                      onClick={(e) =>
                        navigate("/", {
                          state: {
                            scrollRef: "aboutSection",
                          },
                        })
                      }
                    >
                      About Blits Estates
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={(e) =>
                        navigate("/", {
                          state: {
                            scrollRef: "introBlits",
                          },
                        })
                      }
                      // href="/#intro"
                    >
                      What is Blits Token?
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={(e) =>
                        navigate("/", {
                          state: {
                            scrollRef: "howToBuyRef",
                          },
                        })
                      }
                      // href="#action/3.3"
                    >
                      How to Buy Blits Token?
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={(e) =>
                        navigate("/", {
                          state: {
                            scrollRef: "tokenomicsRef",
                          },
                        })
                      }
                      //  href="#action/3.4"
                    >
                      Tokenomics
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // href="#action/3.5"
                      onClick={(e) =>
                        navigate("/", {
                          state: {
                            scrollRef: "faqSection",
                          },
                        })
                      }
                    >
                      FAQ
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Nav.Link onClick={checkAuthandRouteCoin} className="log-btn">
              Buy Blits coin
            </Nav.Link>
            {isLoading === false && (
              <Nav.Link
                onClick={checkAuthandRoute}
                className={`list_property_nav`}
                //   userName !== null ? "d-block" : "d-none"
              >
                + List your property
              </Nav.Link>
            )}
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 nav-login">
            {!isLoading ? (
              <>
                {isLoading === false && userName !== null ? (
                  <div className="d-flex">
                    {userImg !== null && userImg !== "" ? (
                      <img src={userImg} className={`userImg`} />
                    ) : (
                      <img
                        src={`https://blits-estates-assets.s3.amazonaws.com/imageAssets/60111.jpg`}
                        className={`userImg`}
                      />
                    )}
                    <NavDropdown
                      title={userName}
                      id="basic-nav-dropdown"
                      className="blits_nav profile_nav"
                    >
                      <NavDropdown.Item href="/my-profile">
                        My Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/my-investments">
                        My Investments
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/my-blitscoin">
                        My Blits coin
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/my-listings">
                        My Property Listing
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={logoutUser}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                ) : (
                  <>
                    <Nav.Link href="/signup" className="reg-r-btn">
                      Register
                    </Nav.Link>
                    <Nav.Link href="/login" className="log-btn-txt">
                      Login
                    </Nav.Link>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
